import React, { useState, useEffect } from "react";

const ScrollToTopArrow = () => {

    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {    
    if (!showScroll && window.pageYOffset > 400){
        setShowScroll(true)    
    } else if (showScroll && window.pageYOffset <= 400){
        setShowScroll(false)    
    }  
    };
    window.addEventListener('scroll', checkScrollTop);

    const goToTop = () => {
        window.scrollTo(0, 0);
    }


    return (<div style={{position:"fixed", bottom: 30, right: 30}}>
                { showScroll && <button className="btn btn-outline-danger" onClick={ ()=> { goToTop() }}><i className="fa-solid fa-arrow-up"></i></button>}
            </div>);
}


export default ScrollToTopArrow;