import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import { useNavigate } from "react-router-dom";
import { Config } from "../common/config";

const step1 = `${Config.wcurl}/wp-content/uploads/2023/09/bigdata-3327829238.jpg`;
const step2 = `${Config.wcurl}/wp-content/uploads/2023/09/1596707191-big-data-asias-newest-socio-economic-ally.jpeg`;
const step3 = `${Config.wcurl}/wp-content/uploads/2023/09/1624855-1922468992.jpg`;
const step4 = `${Config.wcurl}/wp-content/uploads/2023/09/retail_hero-1024x447-773539819.jpg`;


export const SliderContent = ({title, fontColor, link, children}) => {

  const { label, href } = link || {};
  const navigate = useNavigate();

  const goToLink = () => {
      navigate(href);
  }

  const compStyles = {
                      margin: 'auto', 
                      paddingTop:'15%', 
                      color: fontColor || '#fff', 
                      fontSize: '20px',
                     // border: '0px',
                    //  backgroundColor: 'transparent'
                      backgroundColor: 'rgba(0, 0, 0, 0.6)'
                     };

  return (
    <div className="row opacity-100">
            <div className="col-md-12 col-sm-12 py-3">
                <div className="card mb-3 vh-100 text-center" style={ {...compStyles} }>
                      <div className="card-body">
                        <h5 className="card-title"><h3>{title}</h3></h5>
                        <p className="card-text">{children}</p>
                        {label && <div className="card-footer" style={{margin: 'auto'}}>
                                    <button onClick={goToLink} type="button" className="btn btn-success btn-lg">{label}</button>
                        </div>}
                      </div>
                </div>
            </div>
    </div>
    
  );
}


export default function MainHero() {
  return (
    <HeroSlider
      height={"70vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
      accessibility={{
          orientation: "vertical",
          shouldDisplayButtons: false
        }
      }
    >      

      <Slide
        shouldRenderMask
        label="Bienvenidos !"
        background={{
          backgroundImageSrc: step1
        }} >
            <SliderContent title="¡Bienvenido a Beedots Project!" > 
                No somos una tienda en línea, sino una plataforma centrada en "miners" y "profiles". Nuestra misión es recopilar información de diversos proveedores y organizarla en perfiles para su uso.
            </SliderContent>
      </Slide>

      <Slide
        shouldRenderMask
        label="Nuestro Modelo"
        background={{
          backgroundImageSrc: step2
        }}
      >
            <SliderContent title="Modelo" 
                            link={{ label: 'Modelo Beedots', href: '/about' }}
            > 
                <p>Actualmente, hemos creado perfiles como "Supermercados" y "Ferreterías de Chile" con datos de proveedores importantes. Puedes buscar productos y armar carritos de compra separados por proveedor.</p>                 
            </SliderContent>
      </Slide>

      <Slide
        shouldRenderMask
        label="Objetivo"
        background={{
          backgroundImageSrc: step3
        }}
      >
        <SliderContent title="Objetivo" > 
              Nuestro objetivo va más allá de las compras en línea. Buscamos escalabilidad total, con la capacidad de crear perfiles y miners a medida. Los datos se almacenan en un motor de búsqueda para inteligencia de negocios en constante mejora. 
         </SliderContent>
      </Slide>

      <Slide
        shouldRenderMask
        label="¡ Participa !"
        background={{
          backgroundImageSrc: step4
        }}
      >
            <SliderContent title="¡ Participa !" link={{href:'/contact', label:'Como Participar'}}> 
            Estamos en fase Beta y trabajamos en mejorar. Tus ideas y contribuciones son bienvenidas. Contáctanos en <a style={{color:'#fff'}} href="mailto:knock.knock@beedots.cl">knock.knock@beedots.cl</a> o visita la página ¿Quiero Participar? ¡Gracias por unirte a nosotros! Esperamos que aproveches nuestra plataforma.
            </SliderContent>
        </Slide>

      <MenuNav />
    </HeroSlider>
  );
}