// For Add Item to Cart
export const addCart = (product) =>{
    return {
        type:"ADDITEM",
        payload:product
    }
}

// For Delete Item to Cart
export const delCart = (product) =>{
    return {
        type:"DELITEM",
        payload:product
    }
}

export const clearCart = () =>{
    return {
        type:"CLEARCART"
    }
}


export const setSearchQuery = (prodSearch) =>{
    return {
        type:"SETPRODSEARCHQUERY",
        payload:prodSearch
    }
}

export const setSearchResult = (prodSearch) =>{
    return {
        type:"SETPRODSEARCHRESULT",
        payload:prodSearch
    }
}

export const setProductList = (prodList) =>{
    return {
        type:"SETPRODLIST",
        payload:prodList
    }
}


export const emptyNotifications = () => {
    return {
        type:"EMPTYNOTIFICATION"        
    }
}

export const sendToastNotification = (notification) => {
    const { msg= '', type = 'info',title = 'Message' } = notification;
    return {
        type:"SETNOTIFICATION",
        payload:{ msg, type, title }
    }
}
