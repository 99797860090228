import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addCart, setProductList, setSearchQuery, setSearchResult } from "../redux/action"; //setSearchState
import {bdotsRest} from '../common/bdots-backend'

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import ScrollToTopArrow from './ScrollToTopArrow';

import { useNavigate, useParams } from "react-router-dom";
import { Aggregations } from "./aggregations.cmp/Aggregations";
import ItemPeriodMetric from "./item-period-metric.cmp/ItemPeriodMetric";
import useViewport from "../common/custom-hooks/use-viewport";
import { isEmpty } from "class-validator";

const clpNumberFormat = new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'});


const Products = () => {

  const { productId } = useParams();
  const searchStateQuery = useSelector((state) => state.handleSeachQuery);
  const searchState = useSelector((state) => state.handleSeachResults);
  
  const [data, setData] = useState(searchState?.productList || []);
  const [aggs, setAggs] = useState(null);
  const [loadMoreProducts, setLoadMoreProducts] = useState(true);
  const [loadedProds, setLoadedProds] = useState(0);
  const { currFilter, searchKeyword } = searchStateQuery;

  const { width } = useViewport();
  const [ viewPort, setViewPort ] = useState(width >= 768 ? 'desktop' : 'mobile');


  const [loading, setLoading] = useState(false);
  let componentMounted = true;

  const dispatch = useDispatch();

  

  useEffect(()=>{    
      searchProducts(searchKeyword);      
  }, [currFilter, searchKeyword]);

  // const selectFilter = (switchedFilter) => {
  //     dispatch(setSearchState({currFilter: { ...currFilter, ...switchedFilter }}));
  //     searchProducts(searchKeyword);
  // };

  const addRefineFilter = (refineParams) => {
    console.log(refineParams);
    dispatch(setSearchQuery({currFilter: { ...currFilter, ...refineParams }}));
    if(viewPort === 'mobile') 
        window.scrollTo(0,0);
  };


  const removeRefineFilter = (filterIdentifier) => {
    console.log(filterIdentifier);
    const tempFilters = {...currFilter};
    delete tempFilters[filterIdentifier];
    dispatch(setSearchQuery({currFilter: tempFilters}));
    window.scrollTo(0,0);
  };

  

  const searchProducts = async ( search, params = { next: false } ) => {

        setLoading(true);
        // const searchAttrs = { page: params.next ? (productsPage + 1) : 1, per_page: 12, order: 'asc', orderby: 'price', min_price: 1 };
        const searchAttrs = {};
        if(!params.next) {
          setLoadedProds(0);
          dispatch(setProductList([]));
          setData([]);
        }
        else {
            searchAttrs["itemsPerPage"] = loadedProds + 20;
            setLoadedProds(loadedProds + 20);
        }

        if(search && search !== '') {
          searchAttrs.keyword = search;          
          // dispatch(setSearchState({searchKeyword: search}))
          // setSearchKeyword(search);
        }
        if(currFilter && Object.keys(currFilter)?.length) {
          searchAttrs.query = { bool: {must: [...Object.values(currFilter)]} }
        }        
        const fetchOptions = {
          method: 'POST',
          body: JSON.stringify(searchAttrs)
        }
        const searchResult = (await bdotsRest.callRest('/api/bd/items/rep_factory/search', fetchOptions)).data.body;
        const aggs = searchResult.aggregations;
        setAggs(aggs);
        const prods = searchResult.hits.hits;
        console.log(prods);
        const finalProdList = prods.map(prod => mapProduct({...prod._source, inner_hits: {...prod.inner_hits}}));
        dispatch(setProductList(finalProdList));  
        setLoadMoreProducts(finalProdList.length);
        setData(!params.next ? finalProdList : [...data, ...finalProdList]);
       //  setFilter(finalProdList);
        setLoading(false);       
  };

  const clearFilter = (  ) => {      
    dispatch(setSearchQuery({currFilter: {}}));
  };

  const mapProduct = (prod) => {
    if(!prod) return null;

    prod = {...prod, ...prod.data};
    prod.id = prod.data.productId;
    prod.title = prod.name;
    // prod.price =  parseInt(prod.regular_price);
    prod.description =  prod.name;
    // prod.category = prod.categories[0].name;
    prod.image = prod.images[0]  || '';
    prod.catImage = prod.minerAvatarUrl || '';
    return prod;
  };

  const addProduct = (product) => {
    dispatch(addCart(product))
  }

  useEffect(() => {
    window.scrollTo(0,0);

    const getProducts = async () => {
        setLoading(true);
        if(!data?.length){
          const fetchOptions = {
            method: 'POST'
          }
          if(!isEmpty(productId)) {
            fetchOptions.body = JSON.stringify({query: {term: { "data.productId.keyword":  productId }}});
          }
          const searchResult = (await bdotsRest.callRest('/api/bd/items/rep_factory/search', fetchOptions)).data.body;
          setAggs(searchResult.aggregations);
          const prods = searchResult.hits.hits;
          console.log(prods);
          if (componentMounted) {
            const finalProdList = prods.map(prod => mapProduct({...prod._source, inner_hits: {...prod.inner_hits}}));
            dispatch(setProductList(finalProdList));  
            setData(finalProdList);
            // setFilter(finalProdList); 
            setLoading(false);
          }
        } else {
          setLoading(false);
        }     
  
        return () => {
          componentMounted = false;
        };
    }

    getProducts();

    

    // getProds2();
  }, []);




  const Loading = () => {
    return (
      <>       
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const ShowProducts = () => {
    const navigate = useNavigate();
    const selectProduct = (prod) => {
      // dispatch(setProductList(productList));      
      navigate("/product/" + prod.id);
    }

    return (
      <>       
        <div className="row">
            <div className="col-12" style={{paddingTop: 10, marginTop: 10, borderTop: 'dashed 1px #ccc'}}>
              <Aggregations aggs={aggs} addRefineFilter={addRefineFilter} removeRefineFilter={removeRefineFilter} currFilter={currFilter} clearFilters={clearFilter} />
            </div>
        </div>

        {data.map((product) => {
          return (
            <div id={product.id} key={product.id} className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
              <div className="card text-center h-100" key={product.id} onClick={ ()=> {selectProduct(product)}} style={{cursor: 'pointer'}}>
                <img
                  className="rounded float-left"
                  src={product.catImage}
                  alt="Card"
                  height={40}
                  width={40}
                />
                <img
                  className="card-img-top p-3"
                  src={product.image}
                  alt="Card"
                  style={{maxHeight: 500}}
                                   
                />
                <div className="card-body">
                  <h5 className="card-title">
                    {product.title.substring(0, 12)}...
                  </h5>
                  <p className="card-text" dangerouslySetInnerHTML={{__html: `${product.description.substring(0, 90)} ...`}}></p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item lead"> {clpNumberFormat.format(product.price)}</li>
                </ul>
                <div className="card-body">
                  <button className="btn btn-dark m-1" onClick={(e) => {e.stopPropagation(); addProduct(product);}} title="Agregar Al Carro">
                      Agregar Al Carro <i className="fa-solid fa-cart-plus"></i>
                  </button>
                </div>
                <div className="card-body">
                  <ItemPeriodMetric product={product}/>
                </div>
              </div>
            </div>

          );
        })}
        <div className="d-grid gap-2">          
               { loadMoreProducts && <button className="btn btn-success" onClick={ ()=> { searchProducts(searchKeyword, { next: true }) }}> Cargar Más ... <i className="fa-solid fa-circle-chevron-down"></i></button> }
        </div>
        {loading && <Loading />}
      </>
    );
  };
  return (
    <>
      <div className="container my-3 py-3">
        <div className="row justify-content-center">
          <ShowProducts />          
        </div>
        <ScrollToTopArrow />
      </div>      
    </>
  );
};

export default Products;
