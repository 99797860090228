import { Config } from './config';

class BDotsBackClient {

    static _instance = null;

    static getIntance() {
        if(!BDotsBackClient._instance) {
            BDotsBackClient._instance = new BDotsBackClient();
        }
        return BDotsBackClient._instance; 
    }

    constructor() {        
        this.login();
        this.access_token = null;
    }

    refresh() {
        const me = this;
        setTimeout(() => {
            const options = {
                method: 'POST' 
            };
            me.callRest(`/api/bd/admin/user/authenticate/refresh`, options)
            .then(resp => {
                this.access_token = resp.access_token;
                me.refresh();
            })
            .catch(err => {
                console.error(`Failing on Refresh ${err}`);
                throw err;
            });
            
        },1000 * 60 * 12);
    }

    async login() {
        const { host, publicCredential } = Config.bdotsBack;
        const me = this;
        const options = {
            method: 'POST',
            headers: {  Authorization: `basic ${publicCredential}`,
                       'Content-Type': 'application/json'
                    },
            redirect: 'follow'
        };
        try {
            const response = await fetch(`${host}/api/bd/admin/user/authenticate`, options);
            const res = await response.clone().json()
            console.log(res);
            me.access_token = res.access_token;
            me.refresh();
            return res.access_token;    
        } catch (error) {
            console.error("Fetcherror", error);  
            throw error; 
        }
    }

    async callRest(url, fetchOptions) {
        if(!this.access_token){ 
            this.access_token = await (this.login());
        }
        fetchOptions.headers = fetchOptions.headers || {};
        fetchOptions.headers['authorization'] = `bearer ${this.access_token}`;
        fetchOptions.headers['content-type'] = fetchOptions.headers['content-type'] || 'application/json';
        try {
               const { host } = Config.bdotsBack;
               const response = await fetch(`${host}${url}`, fetchOptions);
               const json = await response.clone().json();     
               return json;
        } catch (error) {
                console.error("Fetcherror", error);    
                throw error;
        }
    }
    
}

export const bdotsRest = BDotsBackClient.getIntance();
