import handleCart from './handleCart'
import handleSearchResults from './handleProdSearch/handleSearchResults';
import handleSeachQuery from './handleProdSearch/handleSearchQuery';
import handleToastNotification from './handleToastNotifications';

import { combineReducers } from "redux";

const rootReducers = combineReducers({
    handleCart,
    handleSearchResults,
    handleSeachQuery,
    handleToastNotification
})
export default rootReducers