import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../redux/action";
import { JsonViewer } from '@textea/json-viewer'

import ScrollToTopArrow from '../components/ScrollToTopArrow'
import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";

import { Footer } from "../components";
import ItemPeriodMetric from "../components/item-period-metric.cmp/ItemPeriodMetric";
const clpNumberFormat = new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'});

const ImagesSliders = ({images}) => { 

  return (
    <HeroSlider
      height={"60vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
      accessibility={{
          orientation: "horizontal",
          shouldDisplayButtons: true,
          backgroundAnimation: 'ZOOM',
        }
      }
    >      
      {images.map(imgUrl => (<Slide
                                shouldRenderMask
                                label=""
                                background={{
                                  backgroundImageSrc: imgUrl
                                }} />))
      }
    </HeroSlider>
  );

};

const ImagesCarousel = ({images}) => {
   
  const [activeIndex, setActiveIndex] = useState(0);
  const totalImages = images.length;  

  const changeActiveIndex = (newIndex) => {
    document.querySelector("#carouselExample .carousel-inner .carousel-item.active").classList.remove("active");
    document.querySelectorAll("#carouselExample .carousel-inner .carousel-item")[newIndex].classList.add("active");
    setActiveIndex(newIndex);
  }

  return (<div id="carouselExample" className="carousel slide">
              <div className="carousel-inner">
                { 
                   images.map((image, index) => {
                       return (<div key={`image_${index}`} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
                                  <img src={image} className="d-block w-100" alt="..." />
                               </div>);
                   })
                }
              </div>
              {(activeIndex > 0) && 
               <button onClick={()=>{changeActiveIndex(activeIndex-1)}} className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>}
              { (activeIndex < totalImages-1) && 
                <button onClick={()=>{changeActiveIndex(activeIndex+1)}} className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>}
        </div>);
};

const Product = () => {
  const { id } = useParams();
  const searchState = useSelector((state) => state.handleSearchResults);
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };

  const goToSearch = ({ goBack } = { goBack: false}) => {    
    if(goBack) {
      navigate(`/product`);
      return ;
    }
    navigate(`/product/productId/${id}`);
    window.scrollTo(0, 0);
}

  useEffect(() => {    
    if(searchState?.productList?.length){
      window.scroll(0,0);
      setLoading(true);
      setLoading2(true);
      const prodIndex = searchState?.productList?.findIndex(p => (p.id === id))
      setProduct(searchState?.productList[prodIndex]);
      setLoading(false);
      const prodListAround = [];
      for(let i=(prodIndex-1), j=(prodIndex+1); (i >= 0 && j < searchState?.productList.length);  i--, j++){
        if(prodListAround.length >= 10) 
            break;
        searchState?.productList[i] && prodListAround.push(searchState?.productList[i]);
        searchState?.productList[j] && prodListAround.push(searchState?.productList[j]);
      } 
      setSimilarProducts(prodListAround);
      setLoading2(false);
    } else {
      goToSearch();
    }
        
  }, [id]);



  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton height={400} width={400} />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton height={30} width={250} />
              <Skeleton height={90} />
              <Skeleton height={40} width={70} />
              <Skeleton height={50} width={110} />
              <Skeleton height={120} />
              <Skeleton height={40} width={110} inline={true} />
              <Skeleton className="mx-3" height={40} width={110} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowProduct = () => {
    const [moreDetails, setMoreDetails] = useState(false);

    if(!product) return(<div>...</div>);

    const { data } = product;

    const detailsJsonObj = {...data};

    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
              <div className="col-12 col-md-3">
                <button type="button" 
                        onClick={() => { goToSearch({goBack: true}); }}
                        className="btn btn-secondary btn-lg"><i className="fa-solid fa-chevron-left"></i>{' '}Volver</button>
              </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 py-3">
              <ImagesCarousel images={data.images} />
            </div>
            <div className="col-md-6 col-md-6 py-5">
              <h4 className="text-uppercase text-muted">{product.minerLabel}</h4>
              <h1 className="display-5">{data.name}</h1>
              <h3 className="display-6  my-4">{clpNumberFormat.format(data.price)}</h3>
              <p className="lead" dangerouslySetInnerHTML={{__html: data[`others_${product.miner}`]?.description || data.description || ''}}></p>
              <p className="lead"> <a target="_blank" href={`${product.minerBaseUrl}/${data.url}`}>{product.minerBaseUrl}/{data.url}</a> </p>

              {/** <ReactJson src={json}/> **/}

              <button
                title="Agregar Al Carro"
                className="btn btn-outline-dark"
                onClick={() => addProduct(product)}
              >
                <i className="fa-solid fa-cart-plus"></i>
              </button>
              <Link title="Ir Al Carro" to="/cart" className="btn btn-dark mx-3">
                <i className="fa fa-cart-shopping mr-1"></i>
              </Link>
            </div>
          </div>
          <div className="row">
            <ItemPeriodMetric product={product}/>
          </div>
          <div className="row">
                      <button onClick={() => {setMoreDetails(!moreDetails)}} title="Más Detalles" className="btn btn-outline-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                          <i className={`fa-solid fa-angles-${moreDetails ? 'up' : 'down'}`}></i>
                      </button>
                      <div className={`collapse ${moreDetails ? 'show' : ''}`} id="collapseExample">
                        <div className="card card-body" style={{marginTop: 10}}>
                            <JsonViewer value={detailsJsonObj} />
                        </div>
                      </div>
            </div>
        </div>
      </>
    );
  };

  const Loading2 = () => {
    return (
      <>
        <div className="my-4 py-4">
          <div className="d-flex">
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowSimilarProduct = () => {
    return (
      <>
        <div className="py-4 my-4">
          <div className="d-flex">
            {similarProducts.map((item) => {

              if(!item) return (<></>);

              return (
                <div key={item.id} className="card mx-4 text-center">
                  <img
                    className="rounded float-left"
                    src={item.catImage}
                    alt="Card"
                    height={40}
                    width={40}
                  />
                  <Link
                      to={"/product/" + item.id}
                      className="btn btn-outlined-dark m-1"
                    >
                      <img
                        className="card-img-top p-3"
                        src={item.image}
                        alt="Card"
                        height={300}
                        width={300}                    
                      />
                  </Link>
                  <div className="card-body">
                    <h5 className="card-title">
                      {item.title.substring(0, 15)}...
                    </h5>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item lead">{clpNumberFormat.format(item.price)}</li>
                  </ul>
                  <div className="card-body">
                    <button
                      className="btn btn-dark m-1"
                      onClick={(e) => { e.stopPropagation(); addProduct(item)}}
                    >
                      <i className="fa-solid fa-cart-plus"></i> {' '}
                      Agregar Al Carro
                    </button>
                  </div>                  
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="container">
        <div className="row">{loading ? <Loading /> : <ShowProduct />}</div>
        <div className="row my-5 py-5">
          <div className="d-none d-md-block">
          <h2 className="">Siguientes Productos En Lista</h2>
            <Marquee
              pauseOnHover={true}
              pauseOnClick={true}
              speed={50}
            >
              {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
            </Marquee>
          </div>
        </div>
      </div>
      <ScrollToTopArrow />
      <Footer />
    </>
  );
};

export default Product;
