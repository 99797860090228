import { isNumber, isNumberString } from "class-validator";
import moment from "moment";
import Chart from "react-google-charts";

export const data = [
  ["Period", "Price"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 1030, 540],
];

export const chartOptions = {
  title: "Cambio de precio durante el periodo ($)",
  curveType: "function",
  legend: "none",
};

export default function ItemPeriodMetric({product}) {
    const priceMetrics = product?.inner_hits?.most_recent?.hits?.hits || [];
    const chartData = [
      ["Period", "Price"],
        ...priceMetrics.reduceRight((acc, n) => {
                const { when } = n._source;
                const { price } = n._source.data;
                const formattedDate = moment(when).format('MMM Do');
                if(!acc.find(acn=> (acn[0] === formattedDate)) && (isNumberString(price) || isNumber(price))) {
                    acc.push([ formattedDate, price]);
                }                           
              return acc;
        }, [])
    ];


    return (<Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={chartData}
                options={chartOptions}
                />)


}