import React from 'react'
import { Footer, ScrollToTopArrow } from "../components";
const AboutPage = () => {
  return (
    <>
      <div className="container my-3 py-3">
        <h1 className="text-center">Participa En Beedots</h1>
        <hr style={{border: 'dashed #DDD 1px', width: 300, margin: 'auto', marginBottom: 15 }} />
        <p className="lead text-center">
        En Beedots, creemos en la colaboración y el apoyo mutuo para llevar nuestra visión a la realidad. Tú también puedes formar parte de este emocionante viaje y contribuir al crecimiento y desarrollo continuo de Beedots. Hay varias formas en las que puedes aportar:
        <br /><br />

  <hr style={{border: 'dashed #DDD 1px', width: 300, margin: 'auto', marginBottom: 15 }} />
  <h3>Compra un Café ☕</h3>
  ¿Quieres brindarnos un pequeño gesto de apoyo? ¡Compra un café virtual para nuestro equipo! Cada café representa un voto de confianza en nuestro trabajo y nos ayuda a mantenernos enfocados en crear la mejor experiencia posible para nuestros usuarios.
  <br /><br />

    <hr style={{border: 'dashed #DDD 1px', width: 300, margin: 'auto', marginBottom: 15 }} />
    <h3>Conviértete en Sponsor 🚀</h3>
    Nuestros sponsors desempeñan un papel crucial en el éxito de Beedots. Si estás buscando una asociación más sólida y deseas destacar tu apoyo, considera ser un sponsor. Además de contribuir al proyecto y recibir menciones y logos en nuestro sitio, también te ofrecemos un beneficio adicional exclusivo: serás el primero en conocer las mejoras y nuevos datos antes de que sean públicos en el sitio. Tu apoyo nos impulsa hacia adelante y juntos lograremos grandes cosas.
    <br /><br />


    <hr style={{border: 'dashed #DDD 1px', width: 300, margin: 'auto', marginBottom: 15 }} />
    <h3>Aporta tus Habilidades Técnicas 🛠️</h3>
    Si eres un apasionado de la tecnología y quieres poner tus habilidades en acción, únete a nuestro equipo. Apreciamos enormemente las contribuciones técnicas. Tu experiencia puede ayudarnos a impulsar nuevas características y mejoras, haciendo de Beedots una plataforma aún más poderosa.
    <br /><br />


    <hr style={{border: 'dashed #DDD 1px', width: 300, margin: 'auto', marginBottom: 15 }} />
    <h3>Comparte tu Visión y Experiencia 👁️</h3>
    Tu perspectiva es valiosa para nosotros. Si tienes ideas, sugerencias o visiones que crees que podrían enriquecer Beedots, queremos escucharte. Tu retroalimentación puede inspirar cambios significativos y garantizar que estemos en sintonía con las necesidades de nuestros usuarios.
    <br /><br />

    <hr style={{border: 'dashed #DDD 1px', width: 300, margin: 'auto', marginBottom: 15 }} />
    <h3>Contacto y Detalles 📬</h3>
    Si estás emocionado por unirte a nosotros en cualquiera de estas formas, o si tienes preguntas adicionales, no dudes en ponerte en contacto con nosotros. Envía un correo electrónico a <a href="mailto:knock.knock@beedots.cl">knock.knock@beedots.cl</a> y estaremos encantados de conversar contigo.
    Agradecemos profundamente a todos nuestros colaboradores, sponsors y a la comunidad que hace posible el avance continuo de Beedots. ¡Tu apoyo marca la diferencia y nos ayuda a construir un futuro exitoso juntos!

    </p>
      </div>
      <ScrollToTopArrow />
      <Footer />
    </>
  )
}

export default AboutPage