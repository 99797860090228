const notification = [];

const handleToastNotifications = (state=notification, action) =>{
   const payload = action.payload
   switch(action.type){
       case "SETNOTIFICATION":
            return [...state, payload];
           
           break;      
       case "EMPTYNOTIFICATION":
            return [];
           
           break;
       default:
           return state
           break;
   }
}

export default handleToastNotifications