const prodSearch = {
    searchKeyword: '',    
    currFilter: {},
};

const handleSeachQuery = (state=prodSearch, action) =>{
   const pSearchObj = action.payload
   switch(action.type){
       case "SETPRODSEARCHQUERY":            
           return {...state, ...pSearchObj}; 
           break;             
       default:
           return state
           break;
   }
}

export default handleSeachQuery