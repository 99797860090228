import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="mb-0 text-center" >
        <div className="row pb-5">
          <div className="col-12">
            <p className="mb-3 mb-md-0" style={{fontSize: 30}}>@Beedots (2023)</p>
          </div>
          <div className="col-12">
            <p className="mb-3 mb-md-0" style={{fontSize: 15}}>@author {" "}
              <a  href="mailto:crtoledo@beedots.cl" className="text-decoration-underline text-dark fs-7" target="_blank" rel="noreferrer">crtoledo</a>              
            </p>
          </div>
          <div className="col-12">
            <p className="mb-3 mb-md-0" style={{fontSize: 10}}>Thanks to  {" "}
              <a  href="https://sahibsingh.dev" style={{fontSize: 10}} className="text-decoration-underline text-dark fs-7" target="_blank" rel="noreferrer">Sahib Singh</a>
              {" "} for Frontent template.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
