import React from 'react'
import { Footer, ScrollToTopArrow } from "../components";
import { Config } from '../common/config';
const AboutPage = () => {
  return (
    <>
      <article style={{width: '70%', margin: 'auto', marginTop: 40}}>	
        <p>Para entender cómo funciona la base de Beedots, es importante conocer algunas partes clave del proyecto:</p>
        <p>Miner y Miner-Source: Imagina que el Miner es como un asistente digital que recopila información de diferentes fuentes llamadas Miner-Sources. Cada Miner-Source es básicamente el lugar donde los datos viven. Por ejemplo, si quisiéramos obtener la lista de productos de https://santaisabel.cl, ese sitio web sería nuestro Miner-Source. Entonces, tenemos un Miner que va al sitio y toma los datos que necesitamos, y los envía a una especie de empacador llamado Beedots Packager.</p>
        <p>Beedots Packager: Este es un servicio dentro de Beedots que recibe los datos recopilados por varios Miners y los organiza en categorías. Imagina que está etiquetando los datos y poniéndolos en carpetas virtuales llamadas Profiles. Estos Profiles están destinados a ciertas personas que necesitan estos datos específicos. Luego, los Profiles se envían a otro servicio llamado Beedots Engine.</p>
        <p>Profile: Un Profile es como un archivo maestro donde se recopila información de diferentes Miners. Es como una carpeta virtual que contiene todos los datos relevantes de un tema específico.</p>  <p>Beedots Engine: Una vez que los Profiles están listos para ser compartidos, el servicio Beedots Engine se encarga de distribuir la información. Envía los datos a un motor de búsqueda interno (como una computadora muy rápida que puede buscar cosas rápidamente) y también a algo llamado “Subscriber”. Un “Subscriber” podría ser cualquier aplicación externa, como un sitio web de compras en línea.</p>
        <img style={{maxWidth: '700'}} 
            decoding="async" 
            fetchpriority="high" 
            src={`${Config.wcurl}/wp-content/uploads/2023/08/design-High-Level-Model-1024x469.jpg`} 
            alt="" 
            className="wp-image-12765" 
            srcset={`${Config.wcurl}/wp-content/uploads/2023/08/design-High-Level-Model-1024x469.jpg 1024w, 
                    ${Config.wcurl}/wp-content/uploads/2023/08/design-High-Level-Model-300x137.jpg 300w, 
                    ${Config.wcurl}/wp-content/uploads/2023/08/design-High-Level-Model-768x352.jpg 768w, 
                    ${Config.wcurl}/wp-content/uploads/2023/08/design-High-Level-Model-1536x703.jpg 1536w, 
                    ${Config.wcurl}/wp-content/uploads/2023/08/design-High-Level-Model-600x275.jpg 600w, 
                    ${Config.wcurl}/wp-content/uploads/2023/08/design-High-Level-Model.jpg 1553w`} 
            sizes="(max-width: 1024px) 100vw, 1024px" />
        <p>Para motivos prácticos. A continuación vemos un caso de ejemplo el cuál está implementado en el sitio beedots.cl</p>
        <img style={{maxWidth: '700'}} 
             decoding="async" 
             src={`${Config.wcurl}/wp-content/uploads/2023/08/design-Current-Example-Model.jpg`} 
             alt="" 
             className="wp-image-12766" 
             srcset={`${Config.wcurl}/wp-content/uploads/2023/08/design-Current-Example-Model.jpg 1683w, 
                     ${Config.wcurl}/wp-content/uploads/2023/08/design-Current-Example-Model-300x110.jpg 300w, 
                     ${Config.wcurl}/wp-content/uploads/2023/08/design-Current-Example-Model-1024x376.jpg 1024w, 
                     ${Config.wcurl}/wp-content/uploads/2023/08/design-Current-Example-Model-768x282.jpg 768w, 
                     ${Config.wcurl}/wp-content/uploads/2023/08/design-Current-Example-Model-1536x564.jpg 1536w, 
                     ${Config.wcurl}/wp-content/uploads/2023/08/design-Current-Example-Model-600x220.jpg 600w`}
            sizes="(max-width: 1024px) 100vw, 1024px" />
        <p>Este sistema está diseñado para crecer fácilmente. Puedes tener más Miners, más Profiles y más Miner-Sources según sea necesario. Con el tiempo, los Profiles se vuelven cada vez más ricos en datos, lo que es muy útil para cualquier cosa que los consumidores puedan necesitar. Puede ser desde mostrar productos en un sitio web de compras, proporcionar información para modelos de inteligencia artificial, generar informes de inteligencia de negocios, ¡y mucho más!</p>
      </article>
      <ScrollToTopArrow />
      <Footer />
    </>
  )
}

export default AboutPage