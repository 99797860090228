import { MainHero, Products, Footer } from "../components";

function Home() {
  return (
    <>      
      <MainHero />
      <Products />
      <Footer />
    </>
  )
}

export default Home