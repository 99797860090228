import React, { useEffect, useState } from "react";
import useViewport from "../../common/custom-hooks/use-viewport";

const clpNumberFormat = new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'});



export const Aggregations = ({aggs, addRefineFilter, removeRefineFilter, currFilter, clearFilters}) => {

    const { width } = useViewport();
    const [ viewPort, setViewPort ] = useState(width >= 768 ? 'desktop' : 'mobile');

    const Summary = ({distinct_prods_count}) => {

        return (<div class="d-flex gap-2 justify-content-center py-2">
                    <span class="badge d-flex p-2 align-items-center text-bg-secondary rounded-pill">
                        <span class="px-1">Total Results: {distinct_prods_count.value}</span>                    
                    </span>
                    { Object.keys(currFilter).length > 0 &&  
                        <span class="badge d-flex p-2 align-items-center text-danger-emphasis bg-danger-subtle rounded-pill">
                            <span class="px-1">Clear Filters</span>
                            <a onClick={(e)=> { e.preventDefault();  clearFilters();}} 
                               className="px-1 text-danger-emphasis" 
                               href="#" >
                                  <i className="fa-solid fa-xmark"></i>
                            </a>
                    </span>}
                    
                </div>)
    }
    
    
    const PriceRanges = ({price_ranges}) => {
        const { label, attr } = price_ranges.meta;
        const filterKeyPrefix = label.replace(/[^a-zA-Z0-9]/ig,'_');
    
        const defineFilter = (node, identi) => {
            const {from, to} = node;
            const nFile = { range: {} };
            nFile.range[attr] = {};
            if(from) {
                nFile.range[attr]["gte"] = from ;
            }
            if(to) {
                nFile.range[attr]["lte"] = to ;
            }
            const temp = {};
            temp[identi] = {...nFile};
            if(!currFilter[identi]){                
                addRefineFilter(temp);                
            }
            else {
                removeRefineFilter(identi);
            }
        };
    
        return (<>
            <label for="customRange2" className="form-label">{label}</label>
            {price_ranges.buckets.filter(n => (n.distinct_count.value > 0)).map(node => {
                            const {from, to, distinct_count, key} = node;
                            const idenfifier = `tag::${filterKeyPrefix}::${key.replace(/[^a-zA-Z0-9]/ig,'_')}`;
                        return (<button key={idenfifier} className={`btn ${!currFilter[idenfifier] ? 'btn-outline-dark': 'btn-success'} btn-sm m-2`} onClick={()=> {defineFilter(node, idenfifier)} }>{`${clpNumberFormat.format(from)} - ${to ? clpNumberFormat.format(to) : '*'}`} ({distinct_count.value})</button>);
             })}
        </>);
    }
    
    const RefineTags = ({aggArea, multiLabelIdx, multiValueIdx}) => {
        const { label, attr } = aggArea.meta;
        const filterKeyPrefix = label.replace(/[^a-zA-Z0-9]/ig,'_');
    
        const defineFilter = (value, identi) => {
            const nFile = { term: {} };
            nFile.term[attr] = value;
            const temp = {};
            temp[identi] = {...nFile};
            if(!currFilter[identi]){                
                addRefineFilter(temp);                
            }
            else {
                removeRefineFilter(identi);
            }
        };   

        return (<>
                    {aggArea.buckets.map(node => {
                        const k = multiValueIdx >= 0 ? node.key[multiValueIdx] : node.key;
                        const label = multiValueIdx >= 0 ? node.key[multiLabelIdx] : k;
                        const idenfifier = `tag::${filterKeyPrefix}::${k.replace(/[^a-zA-Z0-9]/ig,'_')}`;
                        return (<button key={idenfifier} className={`btn ${!currFilter[idenfifier] ? 'btn-outline-dark': 'btn-success'} btn-sm m-2`} onClick={()=> {defineFilter(k, idenfifier)} }>{label} ({node.distinct_count.value})</button>);
                     }) }
                </>);
        
    
    }

    const SelectedFilters = () => {

        return (<div className="col-6 gap-2 justify-content-center py-2">                    
                     { Object.keys(currFilter).map(filtKey => {
                        const [ tag, prefix, filterKey ] = filtKey.split('::');

                        return (<button key={filtKey} style={{borderRadius: 20}} className={`btn btn-success btn-sm m-2`} onClick={()=> {removeRefineFilter(filtKey)} }>{`${prefix} ${filterKey} `}<i className="fa-solid fa-xmark"></i></button>);
{/**                         return (<span class="badge col-6 p-2 align-items-center text-warning-emphasis bg-warning-subtle rounded-pill">
                                        <span class="px-1">{`${prefix} ${filterKey}`}</span>
                                        <a onClick={(e)=> { e.preventDefault();  removeRefineFilter(filtKey);}} 
                                        className="px-1 text-warning-emphasis" 
                                        href="#" 
                                        >
                                            <i className="fa-solid fa-xmark"></i>
                                        </a>
                                </span>);
                     **/}
                    }) }
                </div>)
    }

    if(!aggs) {
        return (<></>)
    }

    return (<>
                <div className="row buttons text-center py-1" style={{paddingBottom: 10, borderBottom: 'dashed 1px #ccc'}}>
                    <div className="col-md-6 col-12" >
                        <Summary distinct_prods_count={aggs.distinct_prods_count} />
                    </div>
                    { viewPort === 'mobile' && <>
                        <div className="col-12" >
                                    <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#filtersNav" aria-controls="filtersNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="fa-solid fa-filter"></i> Filtros
                                    </button>
                        </div>
                        <div className="col-12" >
                               <SelectedFilters />
                        </div>
                        </>
                    }
                </div>                
                <div className={`row collapse navbar-collapse ${viewPort === 'desktop' ? 'show': ''}`} id="filtersNav">
                    <div className="buttons text-center py-2" >                
                        <RefineTags aggArea={aggs.profiles}  />
                        <RefineTags aggArea={aggs.miner} multiLabelIdx={0} multiValueIdx={1} />
                        <RefineTags aggArea={aggs.brands} addRefineFilter={addRefineFilter} />
                    </div>
                    <div className="buttons text-center py-2">
                        <PriceRanges price_ranges={aggs.price_ranges}  />
                    </div>
                </div>    
            </>);
};