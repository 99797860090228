import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { sendToastNotification, emptyNotifications, setSearchQuery, clearCart, addCart } from "../redux/action";
import debounce from 'lodash.debounce';

import './Navbar.mod/styles.css';


const clpNumberFormat = new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'});

const Notifications = ({noti}) => {
    const dispatch = useDispatch();
    const [ pendingNots, setPendingNots ] = useState([]);    

    useEffect(() => {

        const shownNoti = pendingNots.reduce((acc, n) => {
            acc[n.msg.replace(/[\s\-]/ig, '')] = true;
            return acc;
        },{});

        // console.log('map', shownNoti)
        // console.log('ti display', [...pendingNots, ...noti.filter(n => (!shownNoti[n.msg.replace(/[\s\-]/ig, '')]))]);

        setPendingNots([...pendingNots, ...noti.filter(n => (!shownNoti[n.msg.replace(/[\s\-]/ig, '')]))]);

        const hideNotificationsFnc = debounce(() => {
            // console.log('hi');
            setPendingNots([]);
            dispatch(emptyNotifications());
        }, 5000);
        

        hideNotificationsFnc();

        return(() => {
            console.log("Unmounting Notifications");
        })        
    }, [noti]);

    

    return (<div className="toast-container position-fixed bottom-0 end-0 p-3">
                
                {
                    pendingNots.map(n => (<div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                                <div className="toast-header">
                                                    <strong className="me-auto">{n.title}</strong>
                                                    <small className="text-muted">just now</small>
                                                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                                                </div>
                                                <div className="toast-body">
                                                    {n.msg}
                                                </div>
                                            </div>))
                }
                
                
                
            </div>);
}



const Navbar = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.handleCart)
    const notistate = useSelector(state => state.handleToastNotification)
    const searchState = useSelector((state) => state.handleSeachQuery);
    const { searchKeyword } = searchState;
    const [cartItemsLen, setCartItemsLen] = useState(notistate.length);
    const navigate = useNavigate();

    const CartComponent = ({displayOptions = ''}) => {
        return (<div className={`row justify-content-left ${displayOptions}`} id="navbarSupportedContent">
        <div className="btn-group" role="group" >
            <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown">({state.length})<i className="fa fa-cart-shopping mr-1"></i></button>
            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <NavLink to="/cart" 
                             className="dropdown-item" >
                    <i class="fa-regular fa-eye"></i> Ver
                    </NavLink>  
                    <div><hr className="dropdown-divider" /></div>
                    <NavLink to="/cart" 
                             className="dropdown-item text-danger" 
                             onClick={(e) => {emptyCart(e)}}
                    >
                    <i class="fa-solid fa-trash"></i> Borrar 
                    </NavLink>                       
            </div>
        </div>
    </div>);
    }
    
    useEffect(()=> {        
        if(state.length !== cartItemsLen) {
            const lastObj = state[state.length-1]; // [...state].pop();
            console.log('lastObj in cart',lastObj);
            const { title, qty, price } = lastObj || {};
            if (title) {
                const msgToNotify = `${qty} ${title} : ${clpNumberFormat.format(price)} ${qty > 1 ? ' each' : ''} `;
                dispatch(sendToastNotification({title: 'Items In Cart', msg: msgToNotify}));  
                setCartItemsLen(state.length);      
            }
        }
    }, [state]);


    const goToSearch = (e) => {
        e.preventDefault();
        const keyword = document.querySelector("input", e).value;
        dispatch(setSearchQuery({searchKeyword: keyword}));
        navigate(`/product`);
        window.scrollTo(0, 0);
    }

    const clearSearch = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.querySelector("input", e).value = '';
        dispatch(setSearchQuery({searchKeyword: ''}));
        navigate(`/product`);
        window.scrollTo(0, 0);
    }

    const emptyCart = (e) => {
        dispatch(clearCart());
    }

    useEffect(() => {
        let storedCart = window.localStorage.getItem('curr_cart');
        if(storedCart) {
            storedCart = JSON.parse(storedCart);
            if(storedCart.length !== state.length) {
                while(storedCart.length) {
                    dispatch(addCart(storedCart.shift()));
                }
            }
        }
   }, []);

    useEffect(() => {
         window.localStorage.setItem('curr_cart', JSON.stringify([...state]));         
    }, [state]);

    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 sticky-top d-print-none">
            <div className="container">
                <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/"> <img src='/assets/ImageAI-800x600-small.png' width={200} /></NavLink>
                {/**<button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button> **/}
                <CartComponent displayOptions={'d-sm-block d-md-none'} />
                <div className="row justify-content-center" style={{marginTop: 10, margin: 'auto', width: '100%'}}>
                        <div className="col-12 col-md-10 col-lg-8">
                            <form className="card card-sm" onSubmit={goToSearch}> 
                                <div className="card-body row no-gutters align-items-center" style={{paddingTop: 3, paddingBottom: 3}}>
                                    <div className="col-auto"></div>
                                    <div className="col">
                                        <input className="form-control form-control-lg form-control-borderless" defaultValue={searchKeyword} type="search" placeholder="Buscar Productos, Marcas, etc" />
                                    </div>
                                    { searchKeyword.length > 0 && <div className="col-auto">
                                        <button className="btn btn-md btn-outlined-dark" onClick={clearSearch} type="button"><i className="fa-solid fa-xmark"></i></button>
                                    </div>}                                    
                                    <div className="col-auto">
                                        <button className="btn btn-lg btn-success" type="submit"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    </div>

                                </div>
                            </form>
                        </div>
                </div>
                <CartComponent displayOptions={'d-none d-md-block'} />
            </div>
        </nav>
        
        {notistate.length ? <Notifications noti={notistate} /> : <></> }
        
        </>
    )
}

export default Navbar