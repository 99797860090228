const prodSearch = {
    mainCats: [],
    prodTags: [],    
    productList: []
};

const handleSearchResults = (state=prodSearch, action) =>{
   const pSearchObj = action.payload
   switch(action.type){
       case "SETPRODSEARCHRESULT":            
           return {...state, ...pSearchObj}; 
           break;
       case "SETPRODLIST":
               const pList = action.payload
               if(!pList.length) {
                   return { ...state, productList: [] }
               }

               const foundKeys = state.productList.reduce((acc, node) => {
                   acc[node.id] = true;
                   return acc;
               }, {});
               return {...state, productList: [...state.productList, ...pList.filter(n => (!foundKeys[n.id]))]}
               break;             
       default:
           return state
           break;
   }
}

export default handleSearchResults